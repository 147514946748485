(function() {
  var burger = document.querySelector('.burger');
  var nav = document.querySelector('#'+burger.dataset.target);

  burger.addEventListener('click', function(){
    burger.classList.toggle('is-active');
    nav.classList.toggle('is-active');
  });
})();





// (function jump(h){
// var url = location.href;               //Save down the URL without hash.
// location.href = "#"+h;                 //Go to the target element.
// history.replaceState(null,null,url);   //Don't like hashes. Changing it back.
// })();
